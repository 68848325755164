import React, {useState} from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import {graphql,navigate} from "gatsby"
import TextField from "@material-ui/core/TextField/TextField"
import ArrowForwardIcon from "@material-ui/icons/Forward"
import WaveSvg from "../assets/images/Wave.svg"
import {isValidContentfulImage, isValidEmail, isValidSectionField} from "../common/utils/CommonUtils"
import SEO from "../components/seo"
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import BreadCrumbs from "../components/bread-crumbs";
import "./detail-common-style.scss"


const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started",
    },
    {
        title: "Support Groups",
        link: "/support-groups",
    },
    {
        title: "All Groups",
        link: "/support-groups/online-support-groups",
    },

]

export default function (props) {

    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const pageData = props.data.contentfulGroupDetailPage;
    const {topSection, benefits, groupOverview, groupBullets, groupOrganizer, branchLink, groupName} = pageData;

    const joinGroup = (event, email) => {
        event.preventDefault();
        if (isValidEmail(email)) {
            const groupName = topSection.title;
            SegmentAnalytics.track(SegmentEvents.USER_REQUESTED_FOR_JOIN_GROUP,
                {
                    email, groupName, branchLink,
                    appRequested: true,
                }).then(()=>{
                navigate(`/join-group/${props.pageContext.slug}/`)

            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': groupName,
                'event': 'JOIN_GROUP',
                'email': email
            });

            setEmail("");
            setSuccess(true);
            setError(false);


        } else {
            setError(true);
            setSuccess(false)
        }
    };

    return (
        <Layout>
            <SEO title="Group Overview"/>
            <BreadCrumbs currentPage={topSection.title}  breadCrumbsList={breadCrumbsList}/>
            <div className="common-detailed-header">
                <div className="common-detailed-inner">
                    <div className="common-detailed-pattern-content">
                        {
                            isValidSectionField(topSection, "title") &&
                            <h2 className="common-detailed-main-head">{topSection.title}</h2>
                        }
                        {
                            isValidSectionField(topSection, "description") &&
                            <p className="common-detailed-para"
                               dangerouslySetInnerHTML={{__html: topSection.description.description}}></p>
                        }
                        <form method="post"
                              onSubmit={(event) => joinGroup(event, email)}>
                            <div className="get-app-input-wrap">
                                <div className="input-box">
                                    <TextField
                                        id="email-input"
                                        className="number-field"
                                        placeholder="Enter your email"
                                        value={email}
                                        margin="normal"
                                        onChange={event => {
                                            const {value} = event.target;
                                            setEmail(value);
                                            setSuccess(false);
                                            setError(false)
                                        }}
                                    />
                                </div>
                                <Button
                                    id="submit-email"
                                    className="blue-arrow-btn"
                                    type="submit"
                                >
                                    Join Group <ArrowForwardIcon/>
                                </Button>
                                {error ? <p className="error-msg">Please Enter
                                    Valid Email{error}</p> : null}
                                {success ? <p className="success-msg">Subscribed
                                    Successfully {success}</p> : null}
                            </div>
                        </form>

                    </div>
                    {
                        isValidContentfulImage(topSection.imageOrVideo) && (
                            <div className="common-detailed-image-side">
                                <img className="common-detailed-hero-img"
                                     src={topSection.imageOrVideo.file.url}
                                     alt="group-img"/>
                            </div>
                        )
                    }
                </div>
            </div>

            <div className="group-info-wrapper">
                <div className="group-info-inner">
                    <div className="group-info-row">
                        {
                            topSection.groupMeta && topSection.groupMeta.map(
                                groupMeta => (
                                    <div className="single-group-info">
                                        {
                                            isValidContentfulImage(
                                                groupMeta.image) && (
                                                <img className="info-icon"
                                                     src={groupMeta.image.file.url}
                                                     alt="Icon"/>
                                            )
                                        }
                                        <div className="info-content">
                                            {
                                                isValidSectionField(groupMeta,
                                                    "name") &&
                                                <p className="title">{groupMeta.name}</p>
                                            }
                                            {
                                                isValidSectionField(groupMeta,
                                                    "description") &&
                                                <p className="des"
                                                   dangerouslySetInnerHTML={{__html: groupMeta.description}}></p>
                                            }
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                    {
                        isValidSectionField(topSection, "groupExtraDescription")
                        &&
                        <p className="join-group-para">
                            {topSection.groupExtraDescription.groupExtraDescription}
                        </p>
                    }
                </div>
            </div>

            <div className="common-detailed-wave-wrapper"
                 style={{backgroundImage: `url(${WaveSvg})`}}>
                <div className="wave-bg">
                    <div className="common-detailed-feature-inner">
                        {
                            benefits && benefits.map(benefit => (
                                <div className="single-common-detailed-feature">
                                    {
                                        isValidContentfulImage(benefit.image)
                                        && (
                                            <img
                                                className="single-common-detailed-img"
                                                src={benefit.image.file.url}
                                                alt="Icon"/>
                                        )
                                    }
                                    <div
                                        className="common-detailed-feature-content">
                                        {
                                            isValidSectionField(benefit, "name")
                                            &&
                                            <h2 className="head">{benefit.name}</h2>
                                        }
                                        {
                                            isValidSectionField(benefit,
                                                "description") &&
                                            <p className="para"
                                               dangerouslySetInnerHTML={{__html: benefit.description}}></p>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>


            <div className="course-descr-wrapper">
                <div className="course-descr-inner">
                    {groupOverview && (
                        <div className="course-descr-left-side">
                            {
                                isValidSectionField(groupOverview, "title") &&
                                <h2 className="course-descr-head">{groupOverview.title}</h2>
                            }
                            {
                                isValidSectionField(groupOverview,
                                    "description") &&
                                <p className="course-descr-para"
                                   dangerouslySetInnerHTML={{__html: groupOverview.description.description}}></p>
                            }
                        </div>
                    )}
                    {groupBullets && (
                        <div className="course-descr-right-side">
                            {groupBullets.bulletPoints && <h2
                                className="course-descr-head">{groupBullets.title}</h2>}
                            <ul>
                                {
                                    groupBullets.bulletPoints
                                    && groupBullets.bulletPoints.map(bullet => (
                                        <li>
                                            <img
                                                src={require(
                                                    '../assets/images/checkmark-circle-outlined.svg')}
                                                alt="icon"/>
                                            <p>{bullet}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                    )}

                </div>
            </div>

            {groupOrganizer && (
                <div className="organizer-box-wrapper">
                    <div className="organizer-box-inner">
                        {
                            isValidContentfulImage(groupOrganizer.providerImage)
                            && (
                                <img className="group-organizer-img"
                                     src={groupOrganizer.providerImage.file.url}
                                     alt="Organizer"/>
                            )
                        }
                        <div className="organizer-info">
                            <div className="organizer-info-text">
                                <h2 className="main-text">Group Organizer</h2>
                                {
                                    isValidSectionField(groupOrganizer,
                                        "providerName") &&
                                    <h3 className="sub-text">{groupOrganizer.providerName}</h3>
                                }
                                {
                                    isValidSectionField(groupOrganizer,
                                        "approach") &&
                                    <p className="organizer-para">{groupOrganizer.approach.approach}</p>
                                }
                                <a href={`/online-therapy/providers/${groupOrganizer.slug}/`}>
                                    <Button className="outline-btn">View
                                        Profile</Button>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>

            )}


        </Layout>
    )
}
export const query = graphql` 
query groupBySlug($slug:String) {
  contentfulGroupDetailPage(slug: {eq: $slug}) {
    id
    topSection {
      title
         ... on ContentfulPageTopSection {
      description {
        description
      }
           imageOrVideo {
        file {
          contentType
          url
        }
      }
      groupMeta {
        name
        ... on ContentfulSectionMeta{

        description
        image {
          file {
            contentType
            url
          }
        }
      }
     }


      groupExtraDescription {
        groupExtraDescription
      }
      }
    }
    benefits {
      name
      ... on ContentfulSectionMeta{
      description
      image {
        file {
          url
          contentType
        }
      }
      }
    }
    groupOverview {
      title
      description {
        description
      }
    }
    groupBullets {
      bulletPoints
      title
    }
    groupOrganizer {
      providerName
      providerImage {
        file {
          contentType
          url
        }
      }
      slug
      approach {
        approach
      }
    }
    ... on ContentfulGroupDetailPage {
            branchLink
    }
  }
}`;


